import React from 'react';

export default function Logo(props) {
  return (
    <p>Home</p>
    // <svg width={180} height={34} viewBox="0 0 180 34" fill="none" {...props}>
    //   <path
    //     d="M37.504 9.983c-1.515 0-2.763 1.204-2.763 2.723v11.521h2.763v-11.52h6.589V9.982h-6.589zM60.566 17.079V12.68c0-1.492-1.222-2.723-2.763-2.723h-8.927c-1.515 0-2.764 1.204-2.764 2.723v8.798c0 1.492 1.223 2.723 2.764 2.723h8.927c1.515 0 2.763-1.205 2.763-2.723v-4.4zm-2.763 0v4.399h-8.927V12.68h8.927v4.399zM66.093 9.983c-1.515 0-2.764 1.204-2.764 2.723v8.798c0 1.492 1.223 2.723 2.764 2.723l9.724-.13v-2.593h-9.724v-8.798h9.724v-2.592l-9.724-.131zM91.655 9.983H87.91l-5.474 5.734h-1.089V9.983h-2.763v14.218h2.763v-5.76H82.94l5.686 5.76h3.8l-7.307-7.436 6.536-6.782zM95.77 21.478V18.44l11.692-.13v-5.63c0-1.492-1.223-2.723-2.764-2.723h-8.927c-1.515 0-2.763 1.204-2.763 2.723v8.798c0 1.492 1.222 2.723 2.763 2.723l11.691-.131v-2.592H95.771zm0-8.798h8.928v3.037h-8.927V12.68zM114.396 5.82h-1.063l-1.7 2.069v2.068h-2.099v2.723l2.099-.026v8.824c0 1.493 1.222 2.723 2.763 2.723l4.464-.13v-2.593h-4.464v-8.85l4.862-.079V9.957h-4.862V5.82zM133.819 18.44c0-1.492-1.223-2.723-2.764-2.723h-6.961V12.68h9.725v-2.592l-9.725-.131c-1.514 0-2.763 1.204-2.763 2.723v3.037c0 1.493 1.222 2.723 2.763 2.723h6.961v3.038h-9.724v2.592l9.724.13c1.515 0 2.764-1.204 2.764-2.722V18.44zM148.246 9.983h-8.928c-1.514 0-2.763 1.204-2.763 2.723v8.798c0 1.492 1.222 2.723 2.763 2.723l11.691-.13v-2.593h-11.691v-3.037l11.691-.131v-5.63c0-1.518-1.222-2.723-2.763-2.723zm0 5.734h-8.928V12.68h8.928v3.037zM165.463 9.983l-11.691.13v2.593h11.691v3.037l-11.691.131v5.63c0 1.492 1.222 2.723 2.763 2.723h8.928c1.514 0 2.763-1.204 2.763-2.723v-8.798a2.759 2.759 0 00-2.763-2.723zm0 11.495h-8.928V18.44h8.928v3.038zM175.138 12.628l4.862-.079V9.957h-4.862V5.794h-1.063l-1.7 2.068v2.069h-2.099v2.723l2.099-.026v8.824c0 1.492 1.222 2.723 2.763 2.723l4.463-.131v-2.592h-4.463v-8.824z"
    //     fill="#121214"
    //   />
    //   <path
    //     d="M27.434.662l-4.49-.655a.389.389 0 00-.319.079l-1.913 2.46a.593.593 0 00-.106.55c.053.184-.106.341-.292.315l-1.568-.34a.657.657 0 00-.664.235l-6.217 7.855c-.107.157-.32.21-.505.131l-2.365-1.047a.62.62 0 00-.637.052l-1.196.812a.669.669 0 01-.664.052l-.585-.288a.666.666 0 00-.93.42l-.425 1.858c-.106.445.08.89.478 1.126l.904.55 2.896 1.754 1.408.864c.399.236.903.184 1.249-.104l2.364-2.043c.186-.157.479-.21.691-.078l.718.366a.61.61 0 00.717-.104l1.196-1.1a.618.618 0 01.69-.105l1.78.812c.266.13.585.052.771-.183l.824-1.048 6.244-7.881a.636.636 0 00-.372-1.021l-1.382-.314c-.292-.053-.425-.42-.213-.629l2.153-2.644c.212-.315.08-.655-.24-.707zM6.843 16.529c-.133-.078-.266.052-.213.183l.664 1.728c.054.131.054.262.027.393l-.585 2.252c-.026.157.027.314.133.393l1.674 1.283c.16.105.372.105.505-.026l1.754-1.52c.106-.078.239-.13.345-.156l1.993-.21c.133-.026.186-.21.053-.288l-6.35-4.032zM6.258 22.342a.43.43 0 00-.398-.052l-1.408.68c-.08.026-.133.105-.186.184l-3.003 7.305v.052c.027.026.053.053.107.053l.69-.262h.08c.027.026.053.078.027.105L.014 33.889c-.053.078.054.157.107.078l7.785-7.698a.496.496 0 00.133-.261l.159-1.99a.43.43 0 00-.133-.315l-1.807-1.361z"
    //     fill="#8257E6"
    //   />
    // </svg>
  );
}